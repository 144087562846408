<template>
  <div class="d-flex justify-center" :style="styleVar">
    <v-overlay :opacity="0.4" :value="overlay">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-card v-if="!overlay" style="padding-top: 60px" min-width="1031px" flat>
      <v-row>
        <v-col>
          <v-img
            :src="require('@/assets/icon/chevron-left_2@3x.png')"
            max-width="40px"
            max-height="40px"
            @click="$router.go(-1)"
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center" class="mt-5">
        <!-- 封面 -->
        <!-- <v-col sm="5" offset-sm="1" cols="12"> -->
        <v-col cols="4" style="min-width: 480px; max-width: 480px">
          <v-card max-width="480px" width="100%" flat>
            <v-row no-gutters justify="center">
              <v-col cols="12">
                <v-card
                  max-height="480px"
                  max-width="480px"
                  width="100%"
                  class="cover-border"
                >
                  <video-player
                    v-if="goodsData.video"
                    ref="videoPlayer"
                    class="video-player-box vjs-big-play-centered"
                    :options="playerOptions"
                    :playsinline="true"
                  />
                  <v-img
                    v-else
                    :src="goodsData.cover"
                    max-height="480px"
                    max-width="480px"
                    width="100%"
                    aspect-ratio="1"
                    @click="previewImg(goodsData.cover)"
                  />
                </v-card>
              </v-col>
            </v-row>
            <v-row
              v-if="goodsData.physical == 'Y'"
              no-gutters
              justify="center"
              style="margin-top: 30px"
            >
              <v-col>
                <v-btn
                  class="physical-btn"
                  @click="
                    () => {
                      show_physical = true;
                    }
                  "
                >
                  <span class="physical-btn-text-before">📦</span>
                  <span class="physical-btn-text">{{
                    $t("drop.physical")
                  }}</span>
                  <v-img
                    :src="require('@/assets/icon/arrow-right@3x.png')"
                    max-height="18px"
                    max-width="18px"
                    class="d-inline-block"
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- 文字介绍 -->
        <!-- <v-col cols="12" sm="5" class="ml-10"> -->
        <v-col
          cols="4"
          class="d-flex justify-end"
          style="margin-left: 100px; min-width: 450px; max-width: 450px"
        >
          <v-card width="450px" class="mr-0 pr-0" flat tile>
            <!-- logo -->
            <v-row no-gutters>
              <v-col cols="12" class="d-flex">
                <v-img
                  :src="goodsData.brand_logo"
                  class="brand-logo-circle d-inline-block"
                  max-height="40px"
                  max-width="40px"
                  aspect-ratio="1"
                  width="100%"
                />
                <span class="brand-title ml-3">{{ brand_title }}</span>
              </v-col>
            </v-row>
            <!-- 商品名称 -->
            <v-row no-gutters style="margin-top: 10px">
              <v-col cols="12">
                <span class="goods-name">
                  {{ goods_title }}
                </span>
              </v-col>
            </v-row>
            <!-- 价格编号 -->
            <v-row
              v-if="!nft"
              no-gutters
              justify="center"
              style="margin-top: 53px"
            >
              <v-col cols="6" align-self="center" class="d-flex align-center">
                <v-img
                  :src="
                    require('@/assets/icon/' +
                      goodsData.currency.toLowerCase() +
                      '@3x.png')
                  "
                  max-height="26px"
                  max-width="26px"
                  class="d-inline-block"
                />
                <div class="goods-price d-inline-block ml-3">
                  <span>{{ goodsData.price }}</span>
                  <span
                    v-if="['USD', 'CNY'].indexOf(goodsData.currency) === -1"
                    class="ml-1"
                    >{{ goodsData.currency }}</span
                  >
                </div>
              </v-col>
              <v-spacer />
              <v-col cols="5" align-self="center">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="mint-desc-up text-right"
                    align-self="center"
                  >
                    {{ goodsData.remain }}/{{ goodsData.total_supply }}
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="mint-desc-down text-right" align-self="center">
                    {{ $t("drop.currentSellCount") }}/{{
                      $t("drop.total_supply")
                    }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- nft编号 -->
            <v-row v-if="nft" no-gutters style="margin-top: 67px">
              <v-col>
                <span class="nft-serialid-left"
                  >No.{{ goodsData.serialid }}</span
                ><span class="nft-serialid-right"
                  >/{{ goodsData.total_supply }}</span
                >
              </v-col>
            </v-row>
            <!-- 分割线 -->
            <v-row no-gutters class="mt-5">
              <v-col>
                <div class="divider" />
              </v-col>
            </v-row>
            <!-- 商品描述 -->
            <v-row no-gutters class="mt-5">
              <v-col
                ref="goodsdesc"
                class="goods-desc text-pre-wrap"
                :style="{ height: show_extra ? '100%' : '48px' }"
                >{{ goods_desc }}
              </v-col>
            </v-row>
            <!-- 更多信息 -->
            <v-row
              v-show="show_extra_btn"
              justify="center"
              style="margin-top: 10px"
              no-gutters
            >
              <v-col cols="1" align-self="center">
                <v-btn
                  icon
                  height="26"
                  width="26"
                  elevation="0"
                  @click="() => (show_extra = !show_extra)"
                >
                  <v-img
                    v-if="!show_extra"
                    height="26"
                    width="26"
                    :src="require('@/assets/icon/more@3x.png')"
                  />
                  <v-img
                    v-else
                    height="26"
                    width="26"
                    :src="require('@/assets/icon/less@3x.png')"
                  />
                </v-btn>
              </v-col>
            </v-row>
            <!-- 可隐藏信息 -->
            <v-expand-transition>
              <div>
                <!-- 发行 创作者 -->

                <!-- 分割线 -->
                <v-row no-gutters class="mt-5">
                  <v-col>
                    <div class="divider" />
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-5">
                  <v-col cols="6">
                    <span class="goods-prop-label">{{
                      $t("drop.issuer")
                    }}</span>
                  </v-col>
                  <v-col cols="6">
                    <span class="goods-prop-label">{{
                      $t("drop.creator")
                    }}</span>
                  </v-col>
                </v-row>
                <v-row no-gutters style="margin-top: 6px">
                  <v-col cols="6" align-self="center" class="d-flex">
                    <v-img
                      :src="
                        goodsData.issuer_photo
                          ? goodsData.issuer_photo
                          : require('@/assets/avatar/avatar 2.png')
                      "
                      class="border rounded-circle d-inline-block"
                      min-height="40px"
                      min-width="40px"
                      max-height="40px"
                      max-width="40px"
                    />
                    <div
                      class="
                        goods-prop-text-1
                        ml-3
                        d-inline-block d-flex
                        align-self-center
                      "
                    >
                      {{ goodsData.issuer }}
                    </div>
                  </v-col>
                  <v-col cols="6" align-self="center">
                    <span class="goods-prop-text-1">
                      {{ goodsData.creator }}
                    </span>
                  </v-col>
                </v-row>
                <!-- nft来源 -->
                <v-row v-if="nft" no-gutters class="mt-5">
                  <v-col>
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="goods-prop-label">{{
                          $t("drop.source")
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row no-gutters style="margin-top: 6px">
                      <v-col cols="12" align-self="center" class="d-flex">
                        <v-img
                          :src="
                            goodsData.seller_photo
                              ? goodsData.seller_photo
                              : require('@/assets/avatar/avatar 3.png')
                          "
                          class="border rounded-circle"
                          min-height="30px"
                          min-width="30px"
                          max-height="40px"
                          max-width="40px"
                        />
                        <div
                          class="
                            goods-prop-text-1
                            ml-3
                            d-inline-block d-flex
                            align-self-center
                          "
                        >
                          {{ goodsData.seller_ownerid }}
                        </div>
                        <div
                          v-if="goodsData.last_trans_time"
                          class="
                            nft-buy-time-text
                            ml-3
                            d-inline-block d-flex
                            align-self-center
                          "
                        >
                          {{
                            "| " +
                            $t("drop.buy_time") +
                            ":" +
                            goodsData.last_trans_time
                          }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- 系列等级 -->
                <!-- 分割线 -->
                <v-row no-gutters class="mt-5">
                  <v-col>
                    <div class="divider" />
                  </v-col>
                </v-row>
                <v-row v-if="goodsData.collection" no-gutters class="mt-5">
                  <!-- 系列 -->
                  <v-col cols="3" class="extend-goods-prop-label">
                    <span class="goods-prop-label"
                      >{{ $t("drop.collection") }}:</span
                    >
                  </v-col>
                  <v-col cols="9">
                    <span class="goods-prop-text-1">{{
                      goodsData.collection
                    }}</span>
                  </v-col>
                </v-row>
                <!-- 等级 -->
                <v-row
                  v-if="goodsData.level"
                  no-gutters
                  style="margin-top: 13px"
                >
                  <v-col cols="3" class="extend-goods-prop-label">
                    <span class="goods-prop-label"
                      >{{ $t("drop.level") }}:</span
                    >
                  </v-col>
                  <v-col cols="9" align-self="center">
                    <v-img
                      max-width="87px"
                      max-height="19px"
                      :src="
                        require('@/assets/icon/level_' +
                          goodsData.level.toLowerCase() +
                          '@3x.png')
                      "
                    />
                  </v-col>
                </v-row>
                <!-- 性别 -->
                <v-row
                  v-if="goodsData.gender"
                  no-gutters
                  style="margin-top: 13px"
                >
                  <v-col
                    cols="3"
                    align-self="center"
                    class="extend-goods-prop-label"
                  >
                    <span class="goods-prop-label"
                      >{{ $t("drop.gender") }}:</span
                    >
                  </v-col>
                  <v-col cols="9">
                    <span class="goods-prop-text-1">{{
                      goodsData.gender
                        ? $t("drop.gender_map." + goodsData.gender)
                        : ""
                    }}</span>
                  </v-col>
                </v-row>
                <!-- 版税 -->
                <v-row no-gutters style="margin-top: 14px">
                  <v-col
                    cols="3"
                    align-self="center"
                    class="extend-goods-prop-label"
                  >
                    <span class="goods-prop-label"
                      >{{ $t("drop.royalty_fee") }}:</span
                    >
                  </v-col>
                  <v-col cols="9" align-self="center">
                    <span class="goods-prop-text-1">{{
                      convertPercentage(goodsData.royalty_fee)
                    }}</span>
                  </v-col>
                  <v-col />
                </v-row>
                <!-- plus -->
                <v-row
                  v-if="goodsData.plus.length > 0"
                  no-gutters
                  style="margin-top: 10px"
                >
                  <v-col
                    cols="3"
                    align-self="start"
                    class="extend-goods-prop-label"
                  >
                    <span class="goods-prop-label"
                      >{{ $t("drop.plus_label") }}:</span
                    >
                  </v-col>
                  <v-col align-self="center">
                    <v-chip
                      v-for="item in goodsData.plus"
                      :key="item.id"
                      :color="item.bg_color"
                      disabled
                    >
                      {{
                        ($store.getters.language == "zh"
                          ? item.name
                          : item.name_en) +
                        (item.value ? " +" + item.value : "")
                      }}
                    </v-chip>
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <div class="d-inline-block">
                          <v-img
                            :src="require('@/assets/icon/note@3x.png')"
                            max-height="24"
                            max-width="24"
                            v-bind="attrs"
                            style="top: 5px"
                            v-on="on"
                          />
                        </div>
                      </template>
                      <span style="white-space: pre-line">{{
                        $t("drop.plus_tips2")
                      }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
            <!-- 购买按钮 -->
            <v-row
              v-if="!nft"
              style="margin-top: 60px; margin-bottom: 80px"
              o-gutters
            >
              <v-col cols="12" align-self="center" class="d-flex justify-end">
                <v-btn
                  class="btn-base"
                  @click="handleShowBuyDlg"
                  :disabled="sell_out"
                >
                  <span class="buy-button-text">{{
                    goodsData.remain > 0
                      ? goodsData.activity_status === "12"
                        ? $t("drop.btn_buy")
                        : $t("drop.buy_title_sell_out")
                      : $t("drop.buy_title_sell_out")
                  }}</span>
                </v-btn>
              </v-col>
            </v-row>
            <!-- 出售按钮 -->
            <v-row v-if="nft" class="my-15" no-gutters>
              <v-col cols="12" align-self="center" class="d-flex justify-end">
                <v-btn class="btn-base" @click="handleSellNft">
                  <span class="buy-button-text">{{
                    goodsData.status === "keep"
                      ? $t("drop.btn_sell")
                      : $t("drop.btn_sell")
                  }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- 实物弹窗 -->
    <GoodsDialog
      :showdialog="show_physical"
      v-if="goodsData && !overlay"
      :model="goodsData"
      @copyclick="copyStyleCode(goodsData.style_code, $event)"
      :sizelist="size_list"
      @closeclick="
        () => {
          show_physical = false;
        }
      "
      :isen="en"
    />
    <v-dialog
      v-if="goodsData && !overlay"
      max-width="594px"
      max-height="362px"
      content-class="physical-dlg-content"
      persistent
    >
      <v-card flat tile>
        <v-card-text style="padding: 20px 40px 30px 20px">
          <v-row no-gutters>
            <v-col align-self="center" style="max-width: 22px">
              <v-img
                :src="require('@/assets/icon/chevron-left@3x.png')"
                max-width="22px"
                max-height="22px"
              />
            </v-col>
            <v-col cols="6" align-self="center" class="physical-dlg-title">
              {{ $t("drop.physical_title") }}
            </v-col>
            <v-spacer />
            <v-col
              class="d-flex justify-end"
              align-self="center"
              style="max-width: 22px"
            >
              <v-img
                :src="require('@/assets/icon/cross_2@3x.png')"
                max-height="22px"
                max-width="22px"
                style="position: relative; right: -20px"
                @click="
                  () => {
                    show_physical = false;
                  }
                "
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-5 ml-5">
            <v-col cols="4" style="max-width: 150px">
              <v-img
                :src="
                  goodsData.physical_image
                    ? goodsData.physical_image
                    : require('@/assets/drop/physical_default.png')
                "
                aspect-ratio="1"
                max-width="150px"
                max-height="150px"
                style="border-radius: 12px !important"
              />
            </v-col>
            <v-col cols="8" class="ml-5">
              <v-row no-gutters>
                <v-col
                  cols="3"
                  align-self="center"
                  class="goods-prop-label text-left"
                >
                  {{ $t("drop.style_code") }}:
                </v-col>
                <v-col
                  cols="9"
                  align-self="center"
                  class="goods-prop-text-1 d-flex align-center"
                >
                  {{ goodsData.style_code }}
                  <v-img
                    v-if="false"
                    :src="require('@/assets/icon/copy@3x.png')"
                    max-width="16px"
                    max-height="16px"
                    class="d-inline-block"
                    style="margin-left: 6px"
                    @click="copyStyleCode(goodsData.style_code, $event)"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col
                  cols="3"
                  align-self="center"
                  class="goods-prop-label text-left"
                >
                  {{ $t("drop.physical_color") }}:
                </v-col>
                <v-col cols="9" align-self="center" class="goods-prop-text-1">
                  {{ goodsData.color }}
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col
                  :cols="en ? 5 : 3"
                  align-self="center"
                  class="goods-prop-label text-left"
                >
                  {{ $t("drop.delivery_time") }}:
                </v-col>
                <v-col
                  :cols="en ? 6 : 9"
                  align-self="center"
                  class="goods-prop-text-1"
                >
                  {{
                    goodsData.delivery_time &&
                    parseTime(goodsData.delivery_time).slice(0, -3)
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col cols="3" class="goods-prop-label text-left">
                  {{ $t("drop.physical_size") }}:
                </v-col>
                <v-col
                  cols="9"
                  align-self="center"
                  class="goods-prop-text-1 ml-n2"
                >
                  <v-chip
                    v-for="item in size_list"
                    :key="item"
                    class="mx-1 my-1 physical-size-chip"
                    disabled
                  >
                    <span class="physical-size-text">{{ item }}</span>
                  </v-chip>
                </v-col>
              </v-row>
              <!-- 确定按钮 -->
              <v-row v-if="false" class="mt-5">
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn
                    class="btn-base-small px-0"
                    @click="show_physical = false"
                  >
                    <span class="btn-base-small-text">{{
                      $t("drop.agree")
                    }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- 购买弹窗 -->
    <BuyDialog
      :model="goodsData"
      @inc="increment"
      @dec="decrement"
      :buycount="buy_data.count"
      @emailclick="handleBuyGoods"
      @phoneclick="handleBuyGoods"
      @phantomclick="phantomBuy"
      :logintoken="$store.getters.token"
      :loading="buy_processing"
      @buyclick="handleBuyGoods"
      :showbuy="show_buy"
      @closeclick="closeClick"
      :isen="en"
    />
    <v-dialog
      v-if="!nft && !overlay"
      persistent
      min-width="592px"
      min-height="375px"
      content-class="buy-dlg-content"
    >
      <div class="buy-dlg-cross-box">
        <v-img
          :src="require('@/assets/icon/cross@3x.png')"
          max-height="28px"
          max-width="28px"
          @click="
            () => {
              if (!buy_processing) show_buy = false;
            }
          "
        />
      </div>
      <v-card
        tile
        flat
        max-width="592px"
        style="
          border-radius: 19px !important;
          box-shadow: 0px 11px 56px 10px rgba(0, 0, 0, 0.07) !important;
        "
      >
        <v-card-text style="padding: 20px 50px 80px 20px">
          <v-row no-gutters>
            <v-col style="max-width: 22px" align-self="center">
              <v-img
                :src="require('@/assets/icon/chevron-left@3x.png')"
                max-width="22px"
                max-height="22px"
              />
            </v-col>
            <v-col align-self="center" class="ml-2">
              <span class="buy-dlg-title">
                {{ $t("drop.buy_title") }}
              </span>
            </v-col>
          </v-row>
          <v-row no-gutters style="margin-top: 30px">
            <v-col style="max-width: 180px; margin-left: 30px">
              <v-img
                :src="goodsData.cover"
                max-width="180px"
                max-height="180px"
                style="border-radius: 12px"
              />
            </v-col>
            <v-col style="margin-left: 32px">
              <!-- 价格 -->
              <v-row no-gutters>
                <v-col align-self="center" class="buy-dlg-label-text">
                  {{ $t("drop.price") }}
                </v-col>
                <v-col align-self="center" style="max-width: 20px">
                  <v-img
                    :src="
                      require('@/assets/icon/' +
                        goodsData.currency.toLowerCase() +
                        '@3x.png')
                    "
                    height="20px"
                    width="20px"
                  />
                </v-col>
                <v-col
                  align-self="center"
                  class="buy-dlg-text-1"
                  style="margin-left: 10px"
                >
                  <span>{{ goodsData.price }}</span>
                  <span
                    v-if="['USD', 'CNY'].indexOf(goodsData.currency) === -1"
                    class="ml-1"
                    >{{ goodsData.currency }}</span
                  >
                </v-col>
              </v-row>
              <!-- 数量 -->
              <v-row no-gutters>
                <v-col align-self="center" class="buy-dlg-label-text">
                  {{ $t("drop.count") }}
                </v-col>
                <v-col align-self="center" style="max-width: 28px">
                  <v-img
                    :src="require('@/assets/icon/minus@3x.png')"
                    max-height="28px"
                    max-width="28px"
                    @click="buy_data.count -= 1"
                  />
                </v-col>
                <v-col
                  align-self="center"
                  style="max-width: 90px; margin-left: 8px"
                >
                  <v-text-field
                    v-model="buy_data.count"
                    class="inputBuyCount my-auto"
                    type="text"
                    readonly
                    hide-details
                  />
                </v-col>

                <v-col
                  align-self="center"
                  style="max-width: 28px; margin-left: 8px"
                >
                  <v-img
                    :src="require('@/assets/icon/plus@3x.png')"
                    max-height="28px"
                    max-width="28px"
                    @click="buy_data.count += 1"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters style="margin-top: 30px">
                <v-col>
                  <div class="buy-dlg-divider" />
                </v-col>
              </v-row>
              <!-- 共计 -->
              <v-row no-gutters class="mt-5">
                <v-col align-self="center" class="buy-dlg-label-text">
                  {{ $t("drop.total") }}
                </v-col>
                <v-col align-self="center" style="max-width: 20px">
                  <v-img
                    :src="
                      require('@/assets/icon/' +
                        goodsData.paycurrency.toLowerCase() +
                        '@3x.png')
                    "
                    max-height="20px"
                    max-width="20px"
                    class="mx-auto"
                  />
                </v-col>
                <v-col
                  align-self="center"
                  class="buy-dlg-text-2"
                  style="margin-left: 10px"
                >
                  <span>{{
                    showTotalPrice(
                      goodsData.paycurrency,
                      goodsData.payprice * buy_data.count
                    )
                  }}</span>
                  <span
                    v-if="['USD', 'CNY'].indexOf(goodsData.paycurrency) === -1"
                    class="ml-1"
                    >{{ goodsData.paycurrency }}</span
                  >
                </v-col>
              </v-row>
              <!-- 获得编号提示 -->
              <v-row no-gutters class="mt-4">
                <v-col class="buy-dlg-text-3 d-flex align-center">
                  <!-- {{ $t('drop.get_tips') }}:
                  <span v-if="buy_data.count>0" class="d-flex algin-center">No.{{ goodsData.serialids[0] }}</span>
                  <span v-if="buy_data.count>1" class="d-flex algin-center">-No.{{ goodsData.serialids[0]+buy_data.count-1 }}</span>
                  <v-tooltip right tag="div">
                    <template #activator="{ on, attrs }">
                      <v-img
                        v-bind="attrs"
                        :src="require('@/assets/icon/note@3x.png')"
                        max-height="24px"
                        max-width="24px"
                        class="d-inline-block"
                        style="margin-left:6px"
                        v-on="on"
                      />
                    </template>
                    <div style="font-size:8px;max-width:200px; word-wrap:break-word; line-height:15px;">
                      <p>{{ $t('drop.nft_serialid_notice') }}</p>
                    </div>
                  </v-tooltip> -->
                </v-col>
              </v-row>
              <!-- 购买按钮 -->
              <template v-if="$store.getters.token"
                ><v-row class="mt-7">
                  <v-col class="text-right">
                    <v-btn
                      class="btn-base"
                      :loading="buy_processing"
                      @click="handleBuyGoods"
                    >
                      <span class="buy-button-text">{{
                        $t("drop.btn_buy")
                      }}</span>
                    </v-btn>
                  </v-col>
                </v-row></template
              >
              <!--没有登陆显示的按钮-->
              <template v-else
                ><div class="payCardMain">
                  <!--登陆后继续购买-->
                  <div class="prompt">{{ $t("walletInfo.prompt") }}</div>
                  <div class="buttons">
                    <div class="email" @click="handleBuyGoods">
                      <span>{{ $t("walletInfo.email") }}</span>
                    </div>
                    <div class="phone" @click="handleBuyGoods">
                      <span>{{ $t("walletInfo.phone") }}</span>
                    </div>
                    <div class="phantom" @click="phantomBuy">
                      <img
                        draggable="false"
                        src="../assets/buy/phantom.png"
                      /><span>phantom</span>
                    </div>
                  </div>
                </div></template
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 购买弹窗结束-->
    <!-- 剩余时间提示 -->
    <DateDialog
      :isShowDate="show_remaintime_dlg"
      :datetime="goodsData.plan_start_time"
      :isen="en"
      @okclick="closeDatetime"
      @closeclick="closeDatetime"
    />
    <v-dialog max-width="300">
      <v-card v-if="show_remaintime" width="300px" height="150px">
        <v-row
          no-gutters
          justify="center"
          class="pt-5 text-body-1 font-weight-bold"
        >
          {{ $t("drop.remain_time_notice_0") }}&ensp;
          <span v-if="show_remaintime.day > 0">
            {{ show_remaintime.day + $t("drop.remain_time_notice_1") }}
          </span>
          <span v-if="$store.getters.language == 'zh'"
            >{{ show_remaintime.second_part + $t("drop.remain_time_notice_2") }}
          </span>
          <span v-else>&ensp;{{ show_remaintime.second_part }}</span>
        </v-row>
        <v-row
          v-if="$store.getters.language == 'zh'"
          no-gutters
          justify="center"
        >
          <v-col class="text-center text-body-1 font-weight-bold">
            <span>{{ $t("drop.remain_time_notice_3") }} </span>
          </v-col>
        </v-row>
        <v-row justify="center" no-gutters class="mt-7">
          <v-col align-self="center" class="text-center">
            <v-btn
              class="btn-style-one small px-3"
              @click="show_remaintime_dlg = false"
            >
              {{ $t("drop.agree") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <PromptDialog
      @okclick="closePromptDialog"
      @closeclick="closePromptDialog"
      :isShowPrompt="isShowPromptDialog"
    />
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import {
  getMallDetail,
  getMallMerchantDetail,
  getPayList,
  mallBuy,
  getNftDetail,
} from "@/api/drop.js";
import { videoPlayer } from "vue-video-player";
import { parseTime as parseTime_util, formatDecimal } from "@/utils";
import clip from "@/utils/clipboard.js";
import BuyDialog from "../components/BuyDialog/index.vue";
import GoodsDialog from "../components/GoodsDialog/index.vue";
import { NumberHelper } from "../utils/NumberHelper";
import PromptDialog from "../components/PromptDialog";
import DateDialog from "../components/DateDialog";
export default {
  name: "GoodsInfo",
  components: { videoPlayer, BuyDialog, GoodsDialog, PromptDialog, DateDialog },
  props: {
    skcCode: {
      type: String,
      default: "",
    },
    nft: {
      type: Boolean,
      default: false,
    },
    activityNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShowPromptDialog: false,
      goodsData: {},
      overlay: true,
      playerOptions: {
        // videojs options
        autoplay: true,
        // preload: 'true',
        muted: true,
        loop: true,
        language: "zh-CN",
        // playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [],

        poster: "",
        aspectRatio: "1:1",
        fluid: true,
        // liveui: true,
        controlBar: {
          children: [
            "playToggle",
            "ProgressControl",
            // 'PlaybackRateMenuButton',
            "CurrentTimeDisplay",
            "TimeDivider",
            "DurationDisplay",
            "fullscreenToggle",
            { name: "VolumePanel", inline: false },
          ],
        },
      },
      goods_title: "",
      goods_desc: "",
      brand_title: "",
      goods_gender: "",
      show_extra: false,
      show_extra_btn: false,
      calc_show_extra_btn: false,
      levels: ["ur", "ssr", "sr", "r", "n"],
      paycurrencyMap: {
        CNY: { currency: "USDC", rate: 0.153846 },
        USD: { currency: "USDC", rate: 1 },
      },
      show_physical: false,
      size_list: [],
      show_buy: false,
      fee_css: "0%",
      buy_data: {
        count: 1,
      },
      show_remaintime: null,
      show_remaintime_dlg: null,
      buy_processing: false,
      styleVar: {},
      en: true,
      sell_out: false, // sell_out标记
    };
  },
  watch: {
    "$i18n.locale"(n) {
      this.changeLang(n);
      this.en = n === "en";
    },
    "buy_data.count"(n) {
      if (n < 1) {
        this.buy_data.count = 1;
      } else if (n > this.goodsData.serialids_length) {
        this.buy_data.count = this.goodsData.serialids_length;
      }
    },
    "$store.getters.language"(_new) {
      if (_new === "en") {
        this.styleVar["--normal-label-width"] = "95px";
      } else {
        this.styleVar["--normal-label-width"] = "50px";
      }
    },
  },
  created() {
    console.log(this.nft);
    // console.log('activityNo: ' + this.activityNo);

    if (this.nft) {
      getNftDetail(this.skcCode)
        .then((resp) => {
          console.log(resp);
          if (resp.code === 0) {
            this.goodsData = resp.data;
            this.changeLang(this.$i18n.locale);
            this.size_list = this.goodsData.size
              ? JSON.parse(this.goodsData.size)
              : [];
            this.fee_css = this.goodsData.royalty_fee * 1000 + "%";
            if (this.goodsData.video) {
              this.playerOptions.sources.splice(0, 0, {
                type: this.getVideoType(this.goodsData.video),
                src: this.goodsData.video,
              });
              this.playerOptions.poster = this.goodsData.cover;
            }
            this.goodsData._serialids = [0];
            this.overlay = false;
          } else if (resp.code === 5102) {
            this.$notify({
              text: this.$t("drop.nft_error"),
              type: "warn",
            });
            this.$router.replace({ path: "/404" });
          } else {
            console.error(resp);
          }
        })
        .catch((err) => console.error(err));
    } else {
      if (this.$store.getters.token) {
        this.$store.dispatch("GetUserWallet");
      }
      // getMallDetail(this.skcCode)
      getMallMerchantDetail(this.activityNo)
        .then((resp) => {
          if (resp.code === 0) {
            this.goodsData = resp.data;
            this.changeLang(this.$i18n.locale);
            this.size_list = this.goodsData.size
              ? JSON.parse(this.goodsData.size)
              : [];
            this.fee_css = this.goodsData.royalty_fee * 1000 + "%";
            // [[ TEST DATA
            // this.goodsData.currency = 'SOL'
            // this.goodsData.activity_status = '11'
            // this.goodsData.price = 1000000000
            // this.goodsData.physical = 'Y'
            // ]]
            console.log({
              type: this.getVideoType(this.goodsData.video),
              src: this.goodsData.video,
            });

            if (this.goodsData.video) {
              this.playerOptions.sources.splice(0, 0, {
                type: this.getVideoType(this.goodsData.video),
                src: this.goodsData.video,
              });
              this.playerOptions.poster = this.goodsData.cover;
            }
            this.goodsData.paycurrency = this.getPayCurrency(
              this.goodsData.currency
            );
            this.goodsData.payprice =
              this.getPayRate(this.goodsData.currency) * this.goodsData.price;
            this.overlay = false;
            // cxj add sell_out
            if (
              this.goodsData.remain === 0 ||
              this.goodsData.activity_status === "15" ||
              this.goodsData.activity_status === "14"
            ) {
              this.sell_out = true;
              this.goodsData.remain = 0;
            }
          } else if (resp.code === 5007) {
            this.$notify({
              text: this.$t("drop.goods_not_found"),
              type: "warn",
            });
            this.$router.replace({ path: "/404" });
          } else {
            console.error(resp);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    this.en = this.$store.getters.language === "en";
    this.styleVar["--normal-label-width"] =
      this.$store.getters.language === "en" ? "95px" : "50px";
  },
  mounted() {
    this.$nextTick(() => {
      //   console.log('>>>>>>>>>>', this.$refs.goodsdesc)
      //   if (el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth) {
      //     this.show_extra_btn = true
      //   }
    });
  },
  updated() {
    if (!this.calc_show_extra_btn) {
      this.calc_show_extra_btn = true;
      const el = this.$refs.goodsdesc;
      // console.log(el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth)
      if (
        el.offsetHeight < el.scrollHeight ||
        el.offsetWidth < el.scrollWidth
      ) {
        this.show_extra_btn = true;
      }
    }
  },
  methods: {
    closeDatetime() {
      this.show_remaintime_dlg = false;
    },
    getVideoType(url) {
      let fileType = "";
      if (url.indexOf("?ext=") !== -1) {
        const splitArray = url.split("?ext=");
        if (splitArray.length > 1) {
          fileType = `video/${url.split("?ext=")[1]}`;
        }
      } else {
        fileType = "video/mp4";
      }
      return fileType;
    },
    convertPercentage(number) {
      return NumberHelper.convertPercentage(number);
    },
    closeClick() {
      this.show_buy = false;
    },
    increment(number) {
      this.buy_data.count = number;
    },
    decrement(number) {
      this.buy_data.count = number;
    },
    phantomBuy() {
      console.log(this.$i18n.locale);
      const urlParam = {
        activityNo: this.goodsData.activity_no,
        //currency: this.goodsData.paycurrency,
        buyCount: this.buy_data.count,
        lang: this.$i18n.locale == "zh" ? "zh-Hans" : "en",
      };
      let { activityNo, buyCount, lang } = urlParam;
      window.open(
        `${process.env.VUE_APP_WALLET_PAY_URL}/#/${activityNo}/${buyCount}/${lang}`
      );
      this.show_buy = false;
      this.isShowPromptDialog = true;
    },
    closePromptDialog() {
      this.isShowPromptDialog = false;
    },
    parseTime(time, cFormat) {
      return parseTime_util(time, cFormat);
    },
    changeLang(lang) {
      this.playerOptions.language = lang;
      const brandMap = this.$store.getters.brandMap;
      const brand = brandMap[this.goodsData.brand_id];
      if (lang === "en") {
        this.goods_title = this.goodsData.name_en;
        this.brand_title = brand ? brand.brand : this.goodsData.brand;
        this.goods_desc = this.goodsData.desc_en;
      } else if (lang === "zh") {
        this.goods_title = this.goodsData.name;
        this.brand_title = brand ? brand.brandCn : this.goodsData.brand;
        this.goods_desc = this.goodsData.desc;
      }
    },
    handleSellNft() {
      this.$dialog.confirm({
        title: this.$t("notice.title_info"),
        text: this.$t("notice.sell_notice"),
        actions: [
          {
            text: this.$t("notice.ok"),
            color: "primary",
            key: true,
          },
        ],
        width: 300,
      });
    },
    async moneyNotEnough() {
      await this.$dialog.confirm({
        text: this.$t("notice.money_not_enough"),
        title: this.$t("notice.title_info"),
        actions: {
          false: this.$t("notice.cancel"),
          true: {
            color: "primary",
            text: this.$t("notice.go_recharge"),
            handle: () => {
              return new Promise((resolve) => {
                this.$router.push({ path: "/wallet?t=assets" });
                resolve(true);
              });
            },
          },
        },
        width: 250,
      });
    },
    handleBuyGoods() {
      if (this.buy_processing) {
        return;
      }
      if (!this.$store.getters.token) {
        this.$store.dispatch("showLoginDlg", true);
        return;
      }
      if (this.buy_data.count <= 0) {
        return;
      }
      const data = {
        activityNo: this.goodsData.activity_no,
        currency: this.goodsData.paycurrency,
        buyCount: this.buy_data.count,
      };
      const wallet = this.$store.getters.coins;
      const current_have = wallet[this.goodsData.paycurrency] || 0;
      if (current_have < this.buy_data.count * this.goodsData.payprice) {
        this.moneyNotEnough()
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => console.log(err));
        return;
      }
      this.buy_processing = true;
      getPayList(data)
        .then((resp) => {
          if (resp.code === "OIS133") {
            this.$dialog.confirm({
              text: this.$t("notice.buy_num_limit", {
                buy_limit: this.goodsData.buy_limit,
              }),
              title: this.$t("notice.title_attention"),
              actions: [
                {
                  text: this.$t("notice.ok"),
                  color: "primary",
                  key: true,
                },
              ],
              width: 250,
            });
            this.buy_processing = false;
            return;
          } else {
            if (resp.data.items.length === 0) {
              this.$notify({
                text: this.$t("drop.not_support_buy"),
                type: "warn",
              });
              return;
            }
            for (const idx in resp.data.items) {
              const item = resp.data.items[idx];
              console.log("item", item);
              if (
                item.payType === "SOL" ||
                item.payType === "USDC" ||
                item.payType == "REAL"
              ) {
                mallBuy({
                  payType: item.payType,
                  tranPwd: "",
                  buyCount: this.buy_data.count,
                  activityNo: this.goodsData.activity_no,
                })
                  .then((resp) => {
                    this.$notify({
                      text: this.$t("drop.buy_ok"),
                      type: "success",
                    });
                    this.buy_processing = false;
                    this.show_buy = false;
                    this.$router.push({ path: "/nft" });
                  })
                  .catch((err) => {
                    console.error(err);
                    this.buy_processing = false;
                  });
                break;
              }
            }
          }
        })
        .catch((err) => {
          this.buy_processing = false;
          console.error(err);
        });
    },
    previewImg(url) {
      this.$hevueImgPreview(url);
    },
    handleShowBuyDlg() {
      const st = this.goodsData.activity_status;
      if (st === "12" && this.goodsData.serialids_length) {
        this.show_buy = true;
      }
      if (st === "11" || st === "10") {
        this.show_remaintime_dlg = true;
        const now = new Date().getTime();
        const sec = Math.abs(
          this.goodsData.plan_start_time - Math.ceil(now / 1000)
        );
        this.show_remaintime = {};
        this.show_remaintime.day = Math.floor(sec / 86400);
        const sec2 = sec % 86400;
        const hour = Math.floor(sec2 / 3600);
        const sec3 = sec2 % 3600;
        const min = Math.ceil(sec3 / 60);
        this.show_remaintime.second_part =
          ("0" + hour).slice(-2) + ":" + ("0" + min).slice(-2);
      }
    },
    getPayCurrency(currency) {
      const t = this.paycurrencyMap[currency];
      if (!t) {
        return currency;
      } else {
        return t.currency;
      }
    },
    getPayRate(currency) {
      const t = this.paycurrencyMap[currency];
      if (!t) {
        return 1;
      } else {
        return t.rate;
      }
    },
    copyStyleCode(text, event) {
      console.log("copy", text, event);
      //   console.log(event)
      clip(text, event);
      //   console.log(text)
      //   this.$copyText(text).then(function(e) {
      //     alert('Copied')
      //     console.log(e)
      //   }, function(e) {
      //     alert('Can not copy')
      //     console.log(e)
      //   })
    },
    showTotalPrice(paycurrency, price) {
      if (paycurrency === "USDC") {
        return parseFloat(formatDecimal(price, 2));
      } else if (paycurrency === "SOL") {
        return parseFloat(parseFloat(price).toFixed(6));
      } else {
        return parseFloat(parseFloat(price).toFixed(6));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.divider {
  width: 450px;
  height: 1px;
  border-top: 1px solid #eeeff4;
}

.cover-border {
  border-radius: 12px !important;
  overflow: hidden;
}
.physical-btn {
  // max-width: 104px;
  height: 36px !important;
  border-radius: 20px;
  border: 2px solid #000000;
  background-color: white !important;
  padding: 0 20px !important;
}
.physical-btn/deep/.v-btn__content {
  padding-left: 20px;
  padding-right: 20px;
}
.physical-btn:hover {
  background-color: rgba(237, 237, 237, 1) !important;
}
.physical-btn-text-before {
  width: 16px;
  height: 20px;
  line-height: 20px;
  color: #ffffff;
}
.physical-btn-text {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  line-height: 22px;
  margin-left: 3px;
}
.brand-logo-circle {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #eeeff4;
}
.brand-title {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  line-height: 30px;
  opacity: 0.6;
  display: flex;
  align-items: center;
}
.goods-name {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  line-height: 32px;
}
.goods-price {
  font-size: 26px;
  font-weight: bold;
  color: #1947e5;
  line-height: 32px;
}
.mint-desc-up {
  width: 103px;
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  line-height: 22px;
}
.mint-desc-down {
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  opacity: 0.3;
}
.goods-desc {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  overflow: hidden;
}

// 针对中英文设置不同宽度
.extend-goods-prop-label {
  // max-width: 95px;
  // max-width: 50px;
  max-width: var(--normal-label-width);
}

.goods-prop-label {
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  opacity: 0.3;
  text-align: center;
}
.goods-prop-text-1 {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
}
.goods-prop-text-2 {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
}

.slider {
  width: 100%;
  height: 12px;
  border: 2px solid #18191f;
  border-radius: 6px;
}
.slider-left {
  width: var(--fee_css);
  height: 100%;
  background: #fef100;
  border-radius: 6px;
}
.slider-tag {
  margin-top: -8px;
  margin-right: -8px;
}
.slider-title {
  position: relative;
  font-size: 12px;

  font-weight: bold;
  color: #000000;
  line-height: 22px;
}
.slider-title-left {
  position: absolute;
  left: 0;
  opacity: 0.3;
}
.slider-title-center {
  position: absolute;
  left: calc(var(--fee_css) - 6px);
}
.slider-title-right {
  position: absolute;
  left: calc(100% - 24px);
  opacity: 0.3;
}
.level-box {
  height: 28px;
  background-color: rgba(231, 208, 171, 0.3);
  border-radius: 20px;
  color: #6c5631 !important;
  line-height: 15px;
  font-size: 12px;
}
.level-selected {
  height: 100%;
  background: linear-gradient(176deg, #f1e1c4 0%, #e4cca4 100%);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/.v-chip {
  height: 30px;
  border-radius: 20px;
  margin-right: 12px;
  padding: 0px;
  opacity: 1;
  margin-bottom: 8px;
}
/deep/.v-chip__content {
  height: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 15px;
  padding-left: 16px;
  padding-right: 16px;
}

.video-js .vjs-current-time {
  display: block !important;
  padding-right: 1px;
}
.video-js .vjs-duration {
  display: block !important;
  padding-left: 1px;
}
.video-js .vjs-time-divider {
  display: block !important;
  padding-left: 2px;
  padding-right: 2px;
  min-width: 1px;
}

.buy-button-text {
  height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: #231f20;
  line-height: 22px;
  text-transform: capitalize;
}
.nft-serialid-left {
  font-size: 26px;

  font-weight: bold;
  color: #1947e5;
  line-height: 32px;
}
.nft-serialid-right {
  font-size: 26px;

  font-weight: bold;
  color: rgba(0, 0, 0, 0.3);
  line-height: 32px;
}
.nft-buy-time-text {
  font-size: 12px;

  font-weight: 400;
  color: #000000;
  line-height: 17px;
  opacity: 0.4;
}
// 购买框
.buy-dlg-title {
  font-size: 18px;
  height: 25px;
  font-weight: bold;
  color: #18191f;
  line-height: 25px;
}

.buy-dlg-label-text {
  max-width: 86px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
  opacity: 0.3;
}
.buy-dlg-text-1 {
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  line-height: 22px;
}
.buy-dlg-text-2 {
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #1947e5;
  line-height: 22px;
}
.buy-dlg-text-3 {
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 17px;
}
.inputBuyCount {
  border-radius: 4px;
  background-color: #f4f5f7;
  width: 90px;
  height: 30px;
  padding-top: 0px;
  box-shadow: none;
}
.inputBuyCount/deep/.v-input__control > .v-input__slot:before {
  border-style: none;
}
.inputBuyCount/deep/.v-input__control > .v-input__slot:after {
  border-style: none;
}
.inputBuyCount/deep/.v-input__control > .v-input__slot {
  background-color: transparent !important;
}
.inputBuyCount/deep/.v-input__control {
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  color: #000000;
  line-height: 22px;
}
.inputBuyCount/deep/input {
  text-align: center;
}
.buy-dlg-divider {
  max-width: 280px;
  height: 1px;
  border-bottom: 1px dashed #eeeff4;
}
.physical-dlg-title {
  font-size: 18px;
  font-weight: bold;
  color: #18191f;
  line-height: 22px;
}
.physical-size-chip {
  height: 30px;
  min-width: 60px;
  background: #f8f8f8;
  border-radius: 16px;
  text-align: center;
  justify-content: center;
}
.physical-size-chip.theme--light.v-chip:not(.v-chip--active) {
  background-color: rgba(248, 248, 248, 1) !important;
}
.physical-size-text {
  height: 17px;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  line-height: 17px;
  opacity: 0.6;
}
.buy-dlg-cross-box {
  background-color: rgba(0, 0, 0, 0) !important;
  position: relative;
  left: calc(100% + 18px);
  top: 28px;
}

.btn-base {
  max-width: 210px !important;
  width: 100% !important;
  height: 50px !important;
  background: rgba(180, 255, 89, 1) !important;
  border-radius: 12px;
}
.btn-base:hover {
  background-color: rgba(124, 224, 4, 1) !important;
}

.btn-base-small {
  width: 104px !important;
  height: 34px !important;
  background: rgba(180, 255, 89, 1) !important;
  border-radius: 6px;
}
.btn-base-small:hover {
  background-color: rgba(124, 224, 4, 1) !important;
}
.btn-base-small-text {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  line-height: 15px;
}
.buy-dlg-content {
  max-width: 592px;
  background: transparent;
  box-shadow: none;
  border-radius: 19px;
  overflow: visible;
}
.physical-dlg-content {
  max-width: 594px;
  background: #ffffff;
  box-shadow: 0px 11px 56px 10px rgba(0, 0, 0, 0.07);
  border-radius: 18px;
  border: 1px solid #e8e8e8;
}
.payCardMain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 20px;
  bottom: 10px;
}
.payCardMain > .prompt {
  height: 22px;
  font-size: 16px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #18191f;
  line-height: 22px;
}
.payCardMain > .buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.payCardMain > .buttons > .email {
  width: 148px;
  height: 48px;
  border-radius: 12px;
  border: 2px solid #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payCardMain > .buttons > .email > span {
  height: 24px;
  font-size: 16px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #000000;
  line-height: 24px;
}
.payCardMain > .buttons > .phone {
  width: 148px;
  height: 48px;
  background: #000000;
  border-radius: 12px;
  border: 2px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.payCardMain > .buttons > .phone > span {
  display: block;
  font-size: 16px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #ffffff;
}
.payCardMain > .buttons > .phantom {
  width: 166px;
  height: 48px;
  background: #dad1fc;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.payCardMain > .buttons > .phantom > img {
  height: 20px;
  width: 20px;
}
.payCardMain > .buttons > .phantom > span {
  height: 24px;
  font-size: 16px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #000000;
  line-height: 24px;
}
</style>
