<script>
import { defineComponent, ref, getCurrentInstance } from "@vue/composition-api";
import style from "./index.module.scss";
import GlobalVue from "../../common/global/GlobalVue";
import closedIcon from "./closed.png";
import copyIcon from "./copy.png";
import { parseTime as parseTime_util, formatDecimal } from "@/utils";
import { CopyHelper } from "../../utils/CopyHelper";
export default defineComponent({
  //props: ["model", "buycount", "inc", "dec"],
  props: { model: {}, sizelist: {}, showdialog: {}, isen: {} },
  setup(props) {
    console.log(props.isen);
    const _this = GlobalVue.vueInstance;
    let context = getCurrentInstance();
    return () => {
      const labelStyle = props.isen ? style.labelEn : style.label;
      return (
        <v-dialog
          value={props.showdialog}
          on-input={(v) => {
            props.showdialog = v;
          }}
          persistent
        >
          <div class={style.container}>
            <div
              class={style.closed}
              onClick={() => context.emit("closeclick")}
            >
              <img src={closedIcon} />
            </div>
            <div class={style.main}>
              <div class={style.header}>
                <div class={style.title}>{_this.$t("drop.physical_title")}</div>
              </div>
              <div class={style.order}>
                <div class={style.img}>
                  <v-img
                    src={
                      props.model.physical_image
                        ? props.model.physical_image
                        : require("@/assets/drop/physical_default.png")
                    }
                    aspect-ratio="1"
                    max-width="150px"
                    max-height="150px"
                    style="border-radius: 12px !important"
                  />
                </div>
                <div class={style.desc}>
                  <div class={style.row}>
                    <div class={labelStyle}>{_this.$t("drop.style_code")}</div>
                    <div class={style.content}>{props.model.style_code}</div>
                    <img
                      src={copyIcon}
                      class={style.copy}
                      onClick={() =>
                        CopyHelper.copyToClipboard(props.model.style_code)
                      }
                    />
                  </div>
                  <div class={style.row}>
                    <div class={labelStyle}>
                      {_this.$t("drop.physical_color")}
                    </div>
                    <div class={style.content}>{props.model.color}</div>
                  </div>
                  <div class={style.row}>
                    <div class={labelStyle}>
                      {_this.$t("drop.delivery_time")}
                    </div>
                    <div class={style.content}>
                      {props.model.delivery_time &&
                        parseTime_util(props.model.delivery_time).slice(0, -3)}
                    </div>
                  </div>
                  <div class={style.size}>
                    <div class={labelStyle}>
                      {_this.$t("drop.physical_size")}
                    </div>
                    {props.sizelist && props.sizelist.length > 0 && (
                      <div class={style.content}>
                        {props.sizelist.map((item) => (
                          <div class={style.sizeIcon}>{item}</div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-dialog>
      );
    };
  },
});
</script>
