import ultralightCopy from "copy-to-clipboard-ultralight";
import Vue from "vue";
import i18n from "../lang";
export  class CopyHelper {
  static copyToClipboard(text) {
    if (ultralightCopy(text)) {
      Vue.prototype.$notify({
        text: i18n.t("utils.copy_success"),
        type: "success",
      });
    } else {
      Vue.prototype.$notify({
        text: i18n.t("utils.copy_fail"),
        type: "error",
      });
    }
  }
}
