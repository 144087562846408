<script>
import { defineComponent, ref, getCurrentInstance } from "@vue/composition-api";
import style from "./index.module.scss";
import icon from "../../assets/buy/phantom.png";
import GlobalVue from "../../common/global/GlobalVue";
import closed from "../../assets/icon/cross@3x.png";
import { formatDecimal } from "@/utils";
export default defineComponent({
  //props: ["model", "buycount", "inc", "dec"],
  props: {
    model: {
      required: true,
    },
    showbuy: {},
    buycount: {
      type: Number,
      required: true,
    },
    logintoken: {
      required: true,
    },
    loading: {},
    isen: {},
  },
  setup(props) {
    const _this = GlobalVue.vueInstance;
    let context = getCurrentInstance();
    const toggleDialog = () => context.emit("closeclick");
    const showTotalPrice = (paycurrency, price) => {
      if (paycurrency === "USDC") {
        return parseFloat(formatDecimal(price, 2));
      } else if (paycurrency === "SOL") {
        return parseFloat(parseFloat(price).toFixed(6));
      } else {
        return parseFloat(parseFloat(price).toFixed(6));
      }
    };
    return () => {
      const Icon = (
        <v-img
          src={require("@/assets/icon/" +
            props.model.currency.toLowerCase() +
            "@3x.png")}
          height="20px"
          width="20px"
        />
      );
      const suffix = ["USD", "CNY"].indexOf(props.model.currency) === -1 && (
        <span>{props.model.currency}</span>
      );
      const labelStyle = props.isen ? style.labelEn : style.label;
      return (
        <v-dialog
          value={props.showbuy}
          on-input={(v) => {
            props.showbuy = v;
          }}
          persistent
        >
          <div class={style.container}>
            <div class={style.main}>
              <div class={style.desc}>
                <div class={style.imgContainer}>
                  {props.model.cover && (
                    <v-img
                      src={props.model.cover}
                      height="180px"
                      width="180px"
                    />
                  )}
                </div>
                <div class={style.order}>
                  <div class={style.price}>
                    <div class={labelStyle}>{_this.$t("drop.price")}</div>
                    <div class={style.content}>
                      <div class={style.img}>{Icon}</div>
                      <div class={style.font}>
                        {props.model.price} {suffix}
                      </div>
                    </div>
                  </div>
                  <div class={style.amount}>
                    <div class={labelStyle}>{_this.$t("drop.count")}</div>
                    <div class={style.content}>
                      <div
                        class={style.inButton}
                        onClick={() => {
                          let number = props.buycount;
                          number--;
                          context.emit("dec", number);
                        }}
                      >
                        -
                      </div>
                      <div class={style.number}>{props.buycount}</div>
                      <div
                        class={style.deButton}
                        onClick={() => {
                          let number = props.buycount;
                          number++;
                          context.emit("inc", number);
                        }}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <hr class={style.divider} />
                  <div class={style.total}>
                    <div class={labelStyle}>{_this.$t("drop.total")}</div>
                    <div class={style.content}>
                      <div class={style.img}>{Icon}</div>
                      <div class={style.font}>
                        {showTotalPrice(
                          props.model.paycurrency,
                          props.model.payprice * props.buycount
                        )}{" "}
                        {suffix}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class={style.info}></div>
              <div class={style.bottom}>
                <div class={style.prompt}>
                  {!props.logintoken && _this.$t("walletInfo.prompt")}
                </div>
                {props.logintoken ? (
                  <div class={style.buyButton}>
                    {process.env.VUE_APP_ISSHOW_WALLET_PAY_BUTTON && (
                      <div
                        class={style.phantom}
                        onClick={() => context.emit("phantomclick")}
                      >
                        <img draggable="false" src={icon} />
                        <span>phantom</span>
                      </div>
                    )}
                    <v-btn
                      class={style.buy}
                      loading={props.loading}
                      on-click={() => context.emit("buyclick")}
                    >
                      <span class="buy-button-text">
                        {_this.$t("drop.btn_buy")}
                      </span>
                    </v-btn>
                  </div>
                ) : (
                  <div class={style.buttons}>
                    <div
                      class={style.email}
                      onClick={() => context.emit("emailclick")}
                    >
                      <span>{_this.$t("walletInfo.email")}</span>
                    </div>
                    <div
                      class={style.phone}
                      onClick={() => context.emit("phoneclick")}
                    >
                      <span>{_this.$t("walletInfo.phone")}</span>
                    </div>
                    <div
                      class={style.phantom}
                      onClick={() => context.emit("phantomclick")}
                    >
                      <img draggable="false" src={icon} />
                      <span>phantom</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class={style.closed} onClick={() => toggleDialog()}>
              <img src={closed} class={style.closeIcon} />
            </div>
          </div>
        </v-dialog>
      );
    };
  },
});
</script>
