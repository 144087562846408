<script>
import { defineComponent, getCurrentInstance } from "@vue/composition-api";
import style from "./index.module.scss";
import closed from "../../assets/icon/cross@3x.png";
import GlobalVue from "../../common/global/GlobalVue";
export default defineComponent({
  props: {
    isShowPrompt: {},
  },
  setup(props) {
    let context = getCurrentInstance();
    const _this = GlobalVue.vueInstance;
    return () => {
      return (
        <v-dialog
          value={props.isShowPrompt}
          on-input={(v) => {
            props.isShowPrompt = v;
          }}
          persistent
        >
          <div class={style.container}>
            <div class={style.main}>
              <div class={style.content}>
                {_this.$t("promptDialog.content")}
              </div>
              <div class={style.bottom}>
                <div class={style.ok} onClick={() => context.emit("okclick")}>
                  <span>{_this.$t("promptDialog.button")}</span>
                </div>
              </div>
            </div>
            <div
              class={style.closed}
              onClick={() => context.emit("closeclick")}
            >
              <img src={closed} class={style.closeIcon} />
            </div>
          </div>
        </v-dialog>
      );
    };
  },
});
</script>
